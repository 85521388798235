import {Grid, Typography} from '@material-ui/core'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {useTenant} from 'contexts/tenant'
import {FC} from 'react'

interface AppLogoProps {
    width?: string | number
    variant?: 'light' | 'dark'
    className?: string
    sappio?: boolean
}

export const AppLogo: FC<AppLogoProps> = ({width, className, variant, sappio}) => {
    const classes = useStyles({width})
    const {tenant} = useTenant()

    const variants = {
        light: require('./assets/logo-light.svg'),
        dark: require('./assets/logo-dark.svg'),
    }

    return (
        <>
            {variant || tenant?.logo ? (
                <Grid xs={12} className={`${classes.root} ${className}`}>
                    {sappio && variant && <img src={variants[variant]} alt={tenant?.name || 'logo'} className={classes.image} id='quote-pdf-logo' />}
                    {!sappio && <img src={tenant?.logo} alt={tenant?.name || 'logo'} className={classes.image} id='quote-pdf-logo' />}
                </Grid>
            ) : (
                <Typography variant='h1' color='inherit'>
                    {tenant?.name}
                </Typography>
            )}
        </>
    )
}

const useStyles = makeStyles<Theme, AppLogoProps>((theme: Theme) => createStyles({
    root: {
        justifyContent: 'space-evenly',
    },
    image: {
        objectFit: 'contain',
        maxWidth: 'inherit',
        width: ({width}) => width,
        paddingTop: theme.spacing(1),
    },
    chip: {
        marginTop: theme.spacing(1),
        fontWeight: theme.typography.fontWeightBold,
    },
}))
